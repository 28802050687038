// 薪酬-奖金
import {
  WAGE
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 年终奖列表
export async function getEndyearBounsList(params) {
  return request(`${WAGE}/yearendBouns/getYearendBounsList`, METHOD.GET, params)
}

// 创建年终奖
export async function createYearendBouns(params) {
  return request(`${WAGE}/yearendBouns/createYearendBouns`, METHOD.POST, params)
}

// 编辑年终奖
export async function updateYearendBouns(params) {
  return request(`${WAGE}/yearendBouns/updateYearendBouns`, METHOD.POST, params)
}

// 删除年终奖
export async function deleteYearendBouns(params) {
  return request(`${WAGE}/yearendBouns/deleteYearendBouns`, METHOD.POST, params)
}

// 年终奖详情
export async function getYearendBouns(params) {
  return request(`${WAGE}/yearendBouns/getYearendBouns`, METHOD.GET, params)
}

// 年终奖发放人员列表
export async function getStaffYearendBouns(params) {
  return request(`${WAGE}/yearendBouns/getStaffYearendBounsList`, METHOD.GET, params)
}

// 编辑年终奖发放人员
export async function updateStaffYearendBouns(params) {
  return request(`${WAGE}/yearendBouns/updateStaffYearendBouns`, METHOD.POST, params)
}

// 删除年终奖发放人员
export async function deleteStaffYearendBouns(params) {
  return request(`${WAGE}/yearendBouns/deleteStaffYearendBouns`, METHOD.POST, params)
}

// 导入年终奖模板
export async function exportStaffYearendBounsTemplate(params) {
  return request(`${WAGE}/yearendBouns/exportStaffYearendBounsTemplate`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 导入年终奖发放人员
export async function importStaffYearendBouns(params) {
  return request(`${WAGE}/yearendBouns/importStaffYearendBouns`, METHOD.POST, params)
}

// 导出人员
export async function exportStaffYearendBouns(params) {
  return request(`${WAGE}/yearendBouns/exportStaffYearendBouns`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 计算年终奖税额
export async function calculateYearendBounsTax(params) {
  return request(`${WAGE}/yearendBouns/calculateStaffYearendBounsTax`, METHOD.POST, params)
}

// 锁定年终奖
export async function lockYearendBouns(params) {
  return request(`${WAGE}/yearendBouns/lockYearendBouns`, METHOD.POST, params)
}

// 解锁年终奖
export async function unlockYearendBouns(params) {
  return request(`${WAGE}/yearendBouns/unlockYearendBouns`, METHOD.POST, params)
}
